import { Injectable } from '@angular/core';
import { BBService } from 'bookingbug-core-js';
import { AppConfig } from 'src/app/app.config';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { DatastoreService } from '../datastore.service';

@Injectable({
  providedIn: 'root'
})
export class DepartmentService {
  private core = AppConfig.settings.core;
  private apiServer = AppConfig.settings.apiServer;
  parentDepartment;
  selectedDepartment;
  selectedLocation;
  locationsList;

  constructor(
    private http: HttpClient,
    private datastoreService: DatastoreService
  ) { }

  async getParentDepartment() {
    return await BBService.company.query(parseInt(this.core.company_id), { excludeChildCompanies: false });
  }

  async getChildDepartments(parentDepartment) {
    return await parentDepartment.$getChildren();
  }

  async set(department) {
    this.selectedDepartment = department;
  }

  async get() {
    return this.selectedDepartment;
  }

  // Get the list of locations from the selected department.
  // First check if a call was already made and we already have 
  // the locations
  async getSelectedDepartmentChildren() {
    const locations = await this.getLocationList();
    if (locations) {
      return locations;
    } else {
      return await this.selectedDepartment.$getChildren();
    }
  }

  async setLocationList(locations) {
    this.locationsList = locations;
  }

  async getLocationList() {
    return this.locationsList;
  }

  async setLocation(location) {
    this.selectedLocation = location;

    // Set location Lat & Long
    location.$getAddress().then((res) => {
      this.datastoreService.latitude = res.lat;
      this.datastoreService.longitude = res.long;
    });
  }

  async getLocation() {
    return this.selectedLocation;
  }

  async getCustomerQuestions() {
    const headers: HttpHeaders = new HttpHeaders({
      'App-Id': this.core.app_id,
      'App-Key': this.core.app_key
    });

    const apiUrl = `${this.apiServer.api_url}/api/${this.apiServer.version}/${this.selectedDepartment.id}/client_details`;
    const details = await this.http.get(apiUrl, { headers }).toPromise();
    return details['questions'];
  }
}
