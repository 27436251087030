import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { AppConfig } from 'src/app/app.config';
import { context } from 'bookingbug-core-js';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: "root",
})
export class ApiService {
  private core = AppConfig.settings.addressApi;
  private apiServer = 'https://staging.bookinglab.co.uk'; // (window.location.hostname == 'localhost') ? 'http://localhost:4201' :
  private token = '177d111d2b35be402209ef0f8ff437d5a365e16bd79e9182b8e439fe10dd6933';

  constructor(private http: HttpClient) { }

  get(postcode) {
    const key = this.core.key
    return this.http.get(
      `https://api.os.uk/search/places/v1/postcode?postcode=${postcode}&key=${key}&dataset=DPA,LPI`
    );
  }

  async getWeekOfBookings(address, company, slot_datetime, weeklyLimit) {
    const headers = { 'Content-Type': 'application/json', "X-BL-TOKEN": `${this.token}` };
    const body = {
      "host": `${context.apiUrl}`,
      "company_id": `${company.id}`,
      "limit": `${company.extra.booking_limit_days}`,
      "address": `${address.substring(0, 50)}`, // Address in jrni is capped to 50 characters
      "current_date": slot_datetime,
      "weeklyLimit": weeklyLimit
    }

    return await this.http.post(`${this.apiServer}/bookingLimit`, body, { headers }).toPromise();
  }


  async checkEmailNumber(email, mobile, company) {
    const parent = await company.$get('parent');
    const headers = { 'Content-Type': 'application/json', "X-BL-TOKEN": `${this.token}` };
    const body = {
      "host": `${context.apiUrl}`,
      "company_id": `${company.id}`,
      "parent_id": `${parent.id}`,
      "email": email ? email : mobile,
      "searchType": "elastic"
    }

    return await this.http.post(`${this.apiServer}/findElasticClient`, body, { headers }).toPromise();
  }

  async getBookingsForUser(company, email) {
    const parent = await company.$get('parent');
    const headers = { 'Content-Type': 'application/json', "X-BL-TOKEN": `${this.token}` };
    const body = {
      "host": `${context.apiUrl}`,
      "parent_id": `${parent.id}`,
      "company_id": `${company.id}`,
      "email": `${email}`,
    }

    return await this.http.post(`${this.apiServer}/findElasticClient`, body, { headers }).toPromise();
  }

  async checkForDoubleBooking(company, slot, email = null, mobile = null) {
    const parent = await company.$get('parent');
    const headers = { 'Content-Type': 'application/json', "X-BL-TOKEN": `${this.token}` };
    const body = {
      "host": `${context.apiUrl}`,
      "company_id": `${company.id}`,
      "parent_id": `${parent.id}`,
      "current_date": slot,
    }

    if (email) {
      body["email"] = email;
    } else {
      body["mobile"] = mobile;
    }

    // return this.http.post('https://staging.bookinglab.co.uk/unavailableTimes', body, { headers }).toPromise();
    return this.http.post(`${this.apiServer}/unavailableTimes`, body, { headers }).toPromise();
  }

  async checkSlotAvailability(company, slot) {
    const headers = { 'Content-Type': 'application/json', "X-BL-TOKEN": `${this.token}` };
    const body = {
      "host": `${context.apiUrl}`,
      "company_id": `${company.id}`,
      "slot": slot,
    }

    return this.http.post(`${this.apiServer}/slotAvailability`, body, { headers }).toPromise();
  }

  async getAddressException() {
    const headers = { 'Content-Type': 'application/json', 'X-BL-TOKEN': `${this.token}` };
    const body = {
      "host": `${context.apiUrl}`
    }

    return await this.http.post(`${this.apiServer}/addressException`, body, { headers }).toPromise();
  }

  async checkBookingLimit(company, matchString, slot, service) {
    console.log(service);
    const headers = { 'Content-Type': 'application/json', 'X-BL-TOKEN': `${this.token}` };
    const body = {
      "host": `${context.apiUrl}`,
      "company_id": `${company.id}`,
      "match_string": `${matchString.trim()}`,
      "slot": `${slot}`,
      "max_time": service.max_advance_period
    }

    return await this.http.post(`${this.apiServer}/checkBookingLimit`, body, { headers }).toPromise();
  }

  async updateBookingLimit(company, matchString, slot) {
    const headers = { 'Content-Type': 'application/json', 'X-BL-TOKEN': `${this.token}` };
    const body = {
      "host": `${context.apiUrl}`,
      "company_id": `${company.id}`,
      "match_string": `${matchString.trim()}`,
      "slot": `${slot}`
    }

    return await this.http.post(`${this.apiServer}/updateBookingLimit`, body, { headers }).toPromise();
  }

  async getWasteLimit(parent_id: number, matchString: string, date: string) {
    try {

      const headers = {
        'Content-Type': 'application/json',
        'Clienttoken': environment.clientToken
      };

      return await this.http.get(`${environment.apiServer}/waste?company=${parent_id}&matchString=${matchString}&date=${date}`, { headers }).toPromise();
    } catch (error) {
      return (error as Error).message;
    }
  }

  async getConfig(site: string, comp: string) {
    // console.log(`Loading '${environment.companyName}' config.`);
    const headers = {
      'Content-Type': 'application/json',
      'Clienttoken': environment.clientToken
    };
    const body = {
      "client": site,
      "company": comp
    }

    return await this.http.post(`${environment.apiServer}/config`, body, { headers }).toPromise();
  }

  async getToken(site: string, comp: string) {
    // console.log(`Loading '${environment.companyName}' config.`);
    const headers = {
      'Content-Type': 'application/json',
      'Clienttoken': environment.clientToken
    };
    const body = {
      "client": site,
      "company": comp
    }

    return await this.http.post(`${environment.apiServer}/token`, body, { headers }).toPromise();
  }
}