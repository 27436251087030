import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { AppConfig } from "src/app/app.config";

@Injectable({
  providedIn: "root",
})
export class AvailabilityService {
  private core = AppConfig.settings.core;
  private apiServer = AppConfig.settings.apiServer;
  selectedSlot;
  availableTimesService;
  availableDaysService;
  endSlot;

  constructor(private http: HttpClient) { }

  async getSlots(location, params) {
    const headers: HttpHeaders = new HttpHeaders({
      "App-Id": this.core.app_id,
      "App-Key": this.core.app_key,
    });

    const apiUrl = `${this.apiServer.api_url}/api/v5/${location.id}/time_data`;
    return await this.http.get(apiUrl, { headers, params }).toPromise();
  }

  async setSelectedSlot(slot) {
    this.selectedSlot = slot;
  }
  async setEndSlot(slot) {
    this.endSlot = slot;
  }

  async getSelectedSlot() {
    return this.selectedSlot;
  }
  async getEndSlot() {
    return this.endSlot;
  }

  async requestAvailibleTimes(location, service, today, maxDate) {
    const params = {
      company_id: location.id,
      service_id: service.id,
      start_date: today,
      end_date: maxDate,
    };
    const baseResource = await location.$get("times", params);
    return baseResource.times;
  }

  async setAvailableTimes(availableTimes) {
    this.availableTimesService = availableTimes;
  }

  async getTimes() {
    return this.availableTimesService;
  }

  async requestAvailableDays(location, service, today, maxDate) {
    // Use below if the services are set on the parent department
    // const services = await service.$getChildServices();
    // const childService = services.filter(service => service.company_id === location.id);
    // return await childService[0].$get('days', { company_id: location.id, date: today, edate: maxDate });

    //Use below if the services are set on the children departments
    return await service.$get("days", {
      company_id: location.id,
      date: today,
      edate: maxDate,
    });
  }

  async setAvailableDays(availableDays) {
    this.availableDaysService = availableDays;
  }

  async getDays() {
    return this.availableDaysService;
  }

  async requestAvailableDaysService(location, service, today, maxDate) {
    return await service.$get("days", {
      company_id: location.id,
      date: today,
      edate: maxDate,
    });
  }
}
