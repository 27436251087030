import { Component, Input, OnInit } from '@angular/core';
import { DatastoreService } from 'src/app/services/datastore.service';

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss']
})
export class NotificationsComponent implements OnInit {

  @Input() notification: string = ""; // the underscore seperated name of the Business Question which contains the desired notification

  constructor(
    private datastoreService: DatastoreService,
  ) { }

  ngOnInit(): void {
  }
  getNotification() {
    let note = ""
    if ( this.notification !== "") {
      if(this.notification.length !== this.notification.replace("parent","").length){
        if(this.datastoreService.parentDepartment?.extra !== undefined){
          if (this.datastoreService.parentDepartment?.extra[this.notification] !== undefined) {
            note = this.datastoreService.parentDepartment?.extra[this.notification]
          }
        }
      }
      if(this.notification.length !== this.notification.replace("child","").length){
        if(this.datastoreService.selectedDepartment?.extra !== undefined){
          if (this.datastoreService.selectedDepartment.extra[this.notification] !== undefined) {
            note = this.datastoreService.selectedDepartment.extra[this.notification]
          }
        }
      }
    }
    note = note.replace("<a",'<a class="noteLink"')
    return note;
  }
}
