import { Component } from '@angular/core';
import { context } from 'bookingbug-core-js';
import { AppConfig } from './app.config';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { DatastoreService } from './services/datastore.service';
import { AlertService } from './_alert';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'East London Waste Booking Service';
  loadIssue: boolean;

  constructor(
    private translate: TranslateService,
    private router: Router,
    private DataStore: DatastoreService,
    private alertService: AlertService) {
    translate.setDefaultLang('en');

    this.DataStore.notify.subscribe((result) => {
      this.loadIssue = result;
      this.scrollToTop();
      this.alertService.clear();
      this.alertService.error(this.translate.instant('COMMON.LOAD_ISSUE'));
    })
  }

  ngOnInit() {
    // BB Context storage
    context.apiUrl = AppConfig.settings.apiServer.api_url;
    context.apiVersion = AppConfig.settings.apiServer.version;

    // storage event to modify localStorage
    //   window.addEventListener('storage', (event) => {
    //     if (event.storageArea == localStorage) {
    //          let token = localStorage.getItem('token_name');
    //          if(token == undefined) { 
    //            // Perform logout
    //            //Navigate to homepage
    //             this.router.navigate(['/departments']); 
    //          }
    //     }
    // });
    // } 
  }

  scrollToTop() {
    const scrollToTop = window.setInterval(() => {
      const pos = window.pageYOffset;
      if (pos > 0) {
        window.scrollTo(0, pos - 20);
      } else {
        window.clearInterval(scrollToTop);
      }
    }, 10);
  }
}

