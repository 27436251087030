import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { DepartmentService } from 'src/app/services/jrni/department.service';
import { LeafletMapComponent } from 'src/app/leaflet-map/leaflet-map.component';
import { AlertService } from 'src/app/_alert';
import { NgxSpinnerService } from 'ngx-spinner';
import { TranslateService } from '@ngx-translate/core';
import { DatastoreService } from 'src/app/services/datastore.service';
import { AvailabilityService } from 'src/app/services/jrni/availability.service';

@Component({
  selector: 'app-location',
  templateUrl: './location.component.html',
  styleUrls: ['./location.component.scss']
})
export class LocationComponent implements OnInit {

  // Access the leaflet components properties
  @ViewChild(LeafletMapComponent) leafletMapComponent: LeafletMapComponent;

  selectedDepartment;
  pageTitle;
  locationPageText;
  locations;
  selectedLocation;
  mapsRequired: boolean;

  constructor(
    private router: Router,
    private departmentService: DepartmentService,
    private alertService: AlertService,
    private spinner: NgxSpinnerService,
    private translateService: TranslateService,
    private datastoreService: DatastoreService,
    private availabilityService: AvailabilityService
  ) { }

  routerGoBack() {
    this.router.navigate(['/services']);
  }

  restartJourney() {
    this.router.navigate(['/departments']);
  }

  ngOnInit() {
    this.scrollToTop();
    this.spinner.show();
    // Use this.departmentService.getLocation() if the services are set on the children departments
    // Use this.departmentService.get() if the services are set on the parent department
    this.departmentService.get().then(selectedDepartment => {
      if (selectedDepartment === undefined) {
        this.restartJourney();
      } else {
        this.selectedDepartment = selectedDepartment;

        // Get the configurable data
        if (this.selectedDepartment.hasOwnProperty('extra')) {
          // Set the title on the login page to the title from the business question
          this.selectedDepartment.extra.hasOwnProperty('location_page_title') ? this.pageTitle = this.selectedDepartment.extra.location_page_title : this.pageTitle = "Location title not set";
          // Set the extra text on the page to the text taken from the business question
          this.selectedDepartment.extra.hasOwnProperty('location_page_text') ? this.locationPageText = this.selectedDepartment.extra.location_page_text : null;
          // Check if maps are required
          this.selectedDepartment.extra.hasOwnProperty('maps') ? this.selectedDepartment.extra.maps.toLowerCase() === 'disabled' ? this.mapsRequired = false : this.mapsRequired = true : null;
        }

        // Get the children departments (locations) from the selected department
        this.departmentService.getSelectedDepartmentChildren().then(locations => {
          this.departmentService.setLocationList(locations);
          this.locations = locations;
          this.datastoreService.locations = locations;
          this.skipLocationPage();
          this.spinner.hide();
        }, err => {
          this.alertService.error(this.translateService.instant('COMMON.GENERAL_ERR'))
          this.spinner.hide();
        });
      }
    }, err => {
      console.log(err);
      this.alertService.error(this.translateService.instant('COMMON.GENERAL_ERR'))
      this.spinner.hide();
    });
  }

  // If there is only one location then skip over the location page
  skipLocationPage() {
    if (this.locations.length === 1) {
      this.selectedLocation = this.locations[0];
      this.continue();
    }
  }

  updateMapView(lat, long) {
    if (this.mapsRequired) {
      this.leafletMapComponent.renderMapLocation(lat, long);
    }
  }

  selectLocation(location) {
    this.selectedLocation = location;
    this.continue()
  }

  continue() {
    // Use this.departmentService.getLocation() if the services are set on the children departments
    // Use this.departmentService.get() if the services are set on the parent department
    this.departmentService.get().then(location => {
      if (this.selectedLocation) {
        if (location && location !== this.selectedLocation) {
          this.datastoreService.selectedDate = null;
          this.availabilityService.setSelectedSlot(null);
        }
        this.departmentService.setLocation(this.selectedLocation);
        // If location is before services then redirect to services else redirect to calendar
        this.router.navigate(['/calendar']);
      } else {
        this.alertService.warn("Please select a location before proceeding");

        this.scrollToTop();
      }
    });
  }

  // scroll to the top of the page
  scrollToTop() {
    window.scroll(0, 0);
  }
}
