import { Component, OnInit } from '@angular/core';
import { DepartmentService } from 'src/app/services/jrni/department.service';
import { AlertService } from 'src/app/_alert';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { DatastoreService } from 'src/app/services/datastore.service';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import * as _ from "lodash";
import { ApiService } from 'src/app/services/jrni/api.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-departments',
  templateUrl: './departments.component.html',
  styleUrls: ['./departments.component.scss']
})
export class DepartmentsComponent implements OnInit {

  childrenDepartments: [];
  pageTitle: string;
  faChevronRight = faChevronRight;
  guidanceMsg: string;

  constructor(
    private departmentService: DepartmentService,
    private datastoreService: DatastoreService,
    private apiService: ApiService,
    protected alertService: AlertService,
    private router: Router,
    private translateService: TranslateService,
    private spinner: NgxSpinnerService
  ) { }

  ngOnInit() {
    this.scrollToTop();
    this.spinner.show();
    this.getDepartments();
  }


  async getDepartments() {
    // reset the restricted booking
    this.datastoreService.isRestrictedBooking = false;
    this.datastoreService.isRestrictedAsbestosBooking = false;
    
    let parentDepartment = await this.departmentService.getParentDepartment();
    const pId = parentDepartment.id;
    const pClient = parentDepartment.self.split("://")[1].split(".")[0]
    const token:any = await this.apiService.getToken(pClient,pId)
    if(token){
      environment.clientToken = token.token
    }
    const config = await this.apiService.getConfig(pClient,pId)
    this.datastoreService.config = config
    if(parentDepartment){
      this.pageTitle = parentDepartment.extra.location_page_title;
      this.guidanceMsg = parentDepartment.extra.location_page_guidance_text;
    }
    
    this.datastoreService.parentDepartment = parentDepartment;

    this.datastoreService.extra = parentDepartment.extra;
    let departments = await this.departmentService.getChildDepartments(parentDepartment);
    let sortedDepartments = [];
    sortedDepartments = _.orderBy(departments, ['name', 'asc']) as [];
    this.spinner.hide();

    //@ts-ignore
    this.childrenDepartments = sortedDepartments;

  }

  selectDepartment(department) {
    this.departmentService.set(department);
    this.datastoreService.selectedDepartment = department;
    this.departmentService.setLocation(department);
    // If the department has the setting required login then navigate to login else to services page
    // if (department.hasOwnProperty('extra') && department.extra.hasOwnProperty('login_required')) {
    //   if (department.extra.login_required.toLowerCase() === 'yes') {
    //     this.router.navigate(['/login']);
    //   } else {
    //     if (department.extra.service_or_location_first.toLowerCase() === 'service') {
    //       this.router.navigate(['/services']);
    //     }
    //   }
    // }
  }

  // scroll to the top of the page
  scrollToTop() {
    window.scroll(0, 0);
  }
}
