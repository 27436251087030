import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DepartmentsComponent } from './journey/departments/departments.component';
import { LoginComponent } from './journey/login/login.component';
import { ServicesComponent } from './journey/services/services.component';
import { LocationComponent } from './journey/location/location.component';
import { CalendarComponent } from './journey/calendar/calendar.component';
import { BookingDetailsComponent } from './journey/booking-details/booking-details.component';
import { SummaryComponent } from './journey/summary/summary.component';
import { ConfirmationComponent } from './journey/confirmation/confirmation.component';
import { RegisterComponent } from './journey/register/register.component';
import { MainAccountComponent } from './account-journey/main-account/main-account.component';
import { GuardService } from './services/guards/guard.service';
import { AccountLoginComponent } from './account-journey/account-login/account-login.component';
import { AccountGuardService } from './services/guards/account-guard.service';

const routes: Routes = [
  { path: '', redirectTo: '/locations', pathMatch: 'full' },
  { path: 'departments', component: DepartmentsComponent },
  { path: 'login', component: LoginComponent, data: { breadcrumb: 'Login' } },
  { path: 'services', component: ServicesComponent, data: { breadcrumb: 'Services' } },
  { path: 'locations', component: LocationComponent },
  { path: 'calendar', component: CalendarComponent, data: { breadcrumb: 'Date & Time' } },
  { path: 'booking-details', component: BookingDetailsComponent, data: { breadcrumb: 'Booking Form' } },
  { path: 'summary', component: SummaryComponent, data: { breadcrumb: 'Summary' } },
  { path: 'confirmation', component: ConfirmationComponent, data: { breadcrumb: 'Confirmation' } },
  // { path: 'register', component: RegisterComponent },
  // { path: 'account', component: MainAccountComponent, canActivate: [AccountGuardService] },
  // { path: 'account-login', component: AccountLoginComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
