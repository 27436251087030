import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { AvailabilityService } from 'src/app/services/jrni/availability.service';
import { BasketService } from 'src/app/services/jrni/basket.service';
import { DepartmentService } from 'src/app/services/jrni/department.service';
import { ServicesService } from 'src/app/services/jrni/services.service';
import { AlertService } from 'src/app/_alert';
import { DatastoreService } from 'src/app/services/datastore.service';
import { MemberService } from 'src/app/services/jrni/member.service';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-calendar',
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.scss']
})
export class CalendarComponent implements OnInit {
  selectedDepartment;
  pageTitle;
  calendarPageText;
  minDate;
  maxDate;
  selectedDate: Date;
  currentDate: Date;
  selectedService;
  selectedLocation;
  slots: [];
  selectedSlot;
  availableDays: any;
  component: String;
  bookingsLimit: String;
  faChevronRight = faChevronRight;
  faChevronLeft = faChevronLeft;

  constructor(
    private router: Router,
    private departmentService: DepartmentService,
    private servicesService: ServicesService,
    private availabilityService: AvailabilityService,
    private alertService: AlertService,
    private basketService: BasketService,
    private translateService: TranslateService,
    private spinner: NgxSpinnerService,
    private datastoreService: DatastoreService,
    private memberService: MemberService
  ) {
  }

  routerGoBack() {
    this.router.navigate(['/departments']);
  }

  restartJourney() {
    this.router.navigate(['/departments']);
  }

  ngOnInit() {
    this.scrollToTop();
    this.component = "calendar";
    this.spinner.show();

    this.selectedDepartment = this.datastoreService.selectedDepartment;

    if (this.selectedDepartment) {
      this.departmentService.getLocation().then(selectedLocation => {
        this.selectedLocation = selectedLocation
      });

      let parentDepartment = this.datastoreService.parentDepartment;

      // this.selectedLocation = this.departmentService.getLocation();

      // Set the current date to today for the calendar to default to today
      this.currentDate = new Date();
      this.selectedDate = this.currentDate;

      // Get the configurable data
      if (parentDepartment.hasOwnProperty('extra')) {
        // Set the title on the login page to the title from the business question
        parentDepartment.extra.hasOwnProperty('calendar_page_title') ? this.pageTitle = parentDepartment.extra.calendar_page_title : this.pageTitle = "Calendar title not set";
        // Set the extra text on the page to the text taken from the business question
        parentDepartment.extra.hasOwnProperty('calendar_page_text') ? this.calendarPageText = parentDepartment.extra.calendar_page_text : null;
      }

      // Retrieve the selected service
      this.servicesService.get().then(service => {
        this.selectedService = service;

        // Clear the basket
        this.basketService.deleteAllBaskets(this.selectedDepartment).catch((err) => {
          this.alertService.error(this.translateService.instant('COMMON.GENERAL_ERR'));
        });

        this.spinner.hide();

      });
    } else {
      this.restartJourney();
    }

  }

  continue() {
    this.alertService.clear();
    this.availabilityService.getSelectedSlot().then(slot => {
      if (slot) {
        // Store the selected date
        this.datastoreService.selectedDate = this.selectedDate;
        // Store the selected slot
        this.datastoreService.selectedSlot = slot;
        this.router.navigate(['/booking-details']);
      } else {
        this.alertService.warn(this.translateService.instant('CALENDAR.SLOT_WARNING'));
        this.scrollToTop();
      }
    });
  }

  // scroll to the top of the page
  scrollToTop() {
    window.scroll(0, 0);
  }
}
